<template>
  <footer class="p-10 bg-gray-700 text-neutral-content">
    <div class="footer">
      <nav>
        <header class="footer-title">MyProviders</header>
        <a class="link link-hover" href="/">Home</a>
        <a class="link link-hover" href="/contact">Contact us</a>
        <a class="link link-hover" :href="config.providerServiceUrl"
          >List your practice</a
        >
      </nav>
      <nav>
        <header class="footer-title">Top Search Results</header>
        <a class="link link-hover" href="/dmi"
          >DMI (Dynamic Movement Intervention)</a
        >
        <a class="link link-hover" href="/intensive">Intensive</a>
      </nav>
      <nav>
        <header class="footer-title">About</header>
        <a class="link link-hover" href="/about">About us</a>
        <a class="link link-hover" href="/terms">Terms and condition</a>
        <a class="link link-hover" href="/privacy">Privacy policy</a>
        <a class="link link-hover" href="#" @click="handlePrivacyChoices"
          >Your Privacy Choices
          <img
            src="~/assets/privacyoptions.svg"
            class="w-6 h-6 inline"
            alt="CCPA"
        /></a>
        <a class="link link-hover" href="/sitemap">Sitemap</a>
      </nav>
      <nav>
        <span class="footer-title">Follow Us</span>
        <div class="">
          <a
            class=""
            href="https://www.instagram.com/myproviders_org?igsh=MXdteno5eGhlenVwZA=="
            target="_blank"
          >
            <img
              src="~/assets/icons/instagram-pink.svg"
              class="w-6 h-6"
              alt="Instagram"
            />
          </a>
        </div>
      </nav>
    </div>
    <div class="text-xs mt-10">
      © {{ new Date().getFullYear() }} MyProviders Family LLC
    </div>
  </footer>
</template>

<script setup>
const handlePrivacyChoices = () => {
  window.revisitCkyConsent();
};
const config = useAppConfig();
</script>
